.iothrottling-form {
  .inputs {
    margin-bottom: 15px;

    select {
      font-size: medium;
      width: 100%;
      margin-top:10px;
      padding: 2px;
    }
  }

  label {
    margin-top: 20px;
    display: inline;
    float: left;
  }

  .fields {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 50px;
  }

  .units {
    text-align: right;
    float: right;
    width: 160px;
  }

  .hidden {
    display: none;
  }

  input {
    font-size: medium;
    font-family: Helvetica,sans-serif;
    margin-top: 10px;
    width: 30%;
    text-align: right;
    float: right;
  }

  input:read-only {
    border-bottom: 1px solid transparent;
    background: $btn-disabled-background;
  }

  button {
    margin-top: 20px;
  }

  form {
    input {
      &:required:invalid, &:focus:invalid {
        border-bottom: 1px solid $btn-background-color;
      }
    }
  }
}
