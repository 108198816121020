
.genymotion_instance .fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;

    .player {
      background: black !important;
      height: 100%;
      max-height: 100% !important;
    }

    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 60px);
        height: 100%;
        padding: 0;
    }

    .toolbars{
        position: absolute;
        top:0;
        right: 0;
        width: 48px;
        height: 100%;
    }
}
