.genymotion_instance {

  .toolbar img:hover,
  .qualityChooser img:hover {
      cursor: pointer;
  }

  .rotation {
      background: url(../img/ic_rotation_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .sound_up {
      background: url(../img/ic_sound_up_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .sound_down {
      background: url(../img/ic_sound_down_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .recent {
      background: url(../img/ic-nav_android_multiapp_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .home {
      background: url(../img/ic-nav_android_home_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .back {
      background: url(../img/ic-nav_android_back_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .battery_button {
    background: url(../img/ic-battery_inactive.svg) no-repeat center;
    background-size: contain;

    &:hover, &.active {
      background: url(../img/ic-battery_active.svg) no-repeat center;
      background-size: contain;
    }
  }

  .power {
      background: url(../img/ic_icon_power_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .videoUp {
      background: url(../img/ic_camera_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .size_chooser_widget, .resolution_chooser_widget {
      background: url(../img/ic-resolution_inactive.svg) no-repeat center;
      background-size: contain;
      outline: none;
      border: none;
      color :transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
  }

  .size_chooser_widget:focus, .resolution_chooser_widget:focus {
      outline-style: none;
      box-shadow: none;
      border-color: transparent;
      color: white;
      background: black !important;
  }

  .size_chooser_widget:hover, .resolution_chooser_widget:hover {
      background: url(../img/ic-resolution_active.svg) no-repeat center;
      background-size: contain;
  }

  .genymotion_button:hover {
      cursor: pointer;
  }

  .gps_button, .gps_button.active {
      background: url(../img/ic_location_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .gps_button:hover, .gps_button.active:hover {
      background: url(../img/ic_location_active.svg) no-repeat center;
      background-size: contain;
  }

  .rotation:hover {
      background: url(../img/ic_rotation_active.svg) no-repeat center;
      background-size: contain;
  }

  .sound_up:hover {
      background: url(../img/ic_sound_up_active.svg) no-repeat center;
      background-size: contain;
  }

  .sound_down:hover {
      background: url(../img/ic_sound_down_active.svg) no-repeat center;
      background-size: contain;
  }

  .recent:hover {
      background: url(../img/ic-nav_android_multiapp_active.svg) no-repeat center;
      background-size: contain;
  }

  .back:hover {
      background: url(../img/ic-nav_android_back_active.svg) no-repeat center;
      background-size: contain;
  }

  .home:hover {
      background: url(../img/ic-nav_android_home_active.svg) no-repeat center;
      background-size: contain;
  }

  .fullscreenWidget {
    background: url(../img/ic_fullscreen_inactive.svg) no-repeat center;
    background-size: contain;
  }

  .fullscreenWidget:hover {
      background: url(../img/ic_fullscreen_active.svg) no-repeat center;
      background-size: contain;
  }

  .fullscreenWidget.active, .fullscreenWidget.active:hover {
    background: url(../img/ic_fullscreen_exit_active.svg) no-repeat center;
    background-size: contain;
  }

  .videoUp:hover {
      background: url(../img/ic_camera_active.svg) no-repeat center;
      background-size: contain;
  }

  .power:hover {
      background: url(../img/ic_icon_power_active.svg) no-repeat center;
      background-size: contain;
  }

  .screencast_button {
      background: url(../img/ic-screenshot_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .screencast_button:hover, .screencast_button.active, .screencast_button.active:hover {
      background: url(../img/ic-screenshot_active.svg) no-repeat center;
      background-size: contain;
  }

  .screencast_button_recording {
    background: url(../img/ic-screenshot_active.svg) no-repeat center !important;
    background-size: contain !important;
  }

  .imei_button {
      background: url(../img/ic_id_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .imei_button:hover, .imei_button.active, .imei_button.active:hover {
      background: url(../img/ic_id_active.svg) no-repeat center;
      background-size: contain;
  }

  .network_button {
      background: url(../img/ic_network_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .network_button:hover, .network_button.active, .network_button.active:hover {
      background: url(../img/ic_network_active.svg) no-repeat center;
      background-size: contain;
  }

  .iothrottling_button {
    background: url(../img/ic_diskIO_inactive.svg) no-repeat center;
    background-size: contain;
  }

  .iothrottling_button:hover, .iothrottling_button.active, .iothrottling_button.active:hover {
    background: url(../img/ic_diskIO_active.svg) no-repeat center;
    background-size: contain;
  }

  .phone_button {
      background: url(../img/ic_textandcall_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .phone_button:hover, .phone_button.active {
      background: url(../img/ic_textandcall_active.svg) no-repeat center;
      background-size: contain;
  }

  .uploader_button {
      background: url(../img/ic_installation_inactive.svg) no-repeat center;
      background-size: contain;
  }

  .uploader_button:hover, .uploader_button.active {
      background: url(../img/ic_installation_active.svg) no-repeat center;
      background-size: contain;
  }

  .quality_button {
      background: url(../img/hq.png) no-repeat center;
      background-size: contain;
  }

  .quality_button:hover, .quality_button.active {
      background: url(../img/hq-over.png) no-repeat center;
      background-size: contain;
  }
}
