.genymotion_instance {
  .wrapper {
      display: inline-block;
      border: 10px solid transparent;
  }

  .drag {

  }

  .dragover {
      border: 10px solid #E6195E;
  }

  .uploadProgress {
      display: block;
      position: absolute;
      left: 5%;
      top: 5%;
      z-index: 1;
  }

  .custom-file-upload {
    display: inline;
  }

  input[type="file"] {
      display: none;
  }
}
