/**
 * styles/base/_reset.scss
 * Common / global / setup styles
 */

html, body {
  margin: 0;
}

*, *::after, *::before {
 box-sizing: border-box;
}

.genymotion_instance {
  text-align: center;
}
