.gps {
    &-form {
        label {
            margin-top: 10px;
        }
        input {
            &.error {
                border-bottom: 1px solid $btn-background-color;
            }
        }
    }
    &-submit {
        margin-top: 10px;
    }
    &-geoloc {
        margin-top: 10px;
    }
}

.gps-mapview {
    height: 460px;
    .mapview {
        position: absolute;
        bottom: 76px;
        left: 26px;
        right: 26px;
        top: 26px;
        background-color: #fff;
    }

    .gps-mapview-capture {
        position: absolute;
        bottom: 20px;
        left: calc(50% + 80px);
    }

    .gps-mapview-cancel {
        position: absolute;
        bottom: 20px;
        right: calc(50% + 80px);
    }
}
