.battery-form {

  input:focus {
      outline: 0;
  }

  .charging-group {
      margin-top: 15px;
    .charging-checkbox {
      width: 15px;
      float: left;
      margin: 0;
    }
    .charging-status {
      height: 32px;
      line-height: 32px;
      font-weight: bold;
      margin-left: 20px;
      &.charging {
        color: $accent-color-light;
      }
    }
  }

  .charge-level-group {
    height: 75px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    * {
      width: 33%;
      text-align: center;
    }
  }

  .charge-level-group {
    .charge-image {
      position: relative;
      width: 35px;
      height: 60px;
      background: url(../img/ic-battery_empty.svg) no-repeat center;
      background-size: 32px;
      margin: 0 auto;
      .charge-image-overlay {
        position: absolute;
        left: 10%;
        bottom: 3px;
        width: 80%;
        height: 0;
        background-color: white;
      }
      .full-charge-image-overlay {
        position: absolute;
        left: 37%;
        bottom: 10px;
        width: 27%;
        height: 0;
        background-color: white;
      }
    }

    .charge-input {
      width: 80px;
      margin: 0 4px 0 0;
    }

    .charge-slider {
      margin: 0;
      width: 65px;
      height: 31px;
      transform: rotate(270deg);
      padding: 0 3px;
    }
  }
}
