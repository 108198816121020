
.phone-form {
  .phone-group {
    &:first-child {
      margin-bottom: 12px;
    }
  }

  input, textarea {
    margin-bottom: 4px;
  }

  textarea {
    resize: none;
    width: 100%;
  }
}
