
.genymotion_instance {

  &:focus {
    outline: 0;
  }

  .video {
      margin: 0;
      max-width: 100%;
  }

  ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
  }

  li img {
      width: 24px;
  }

  .genymotion_button {
      display: block;
      width: 32px;
      height: 26px;
      margin: 16px auto 16px auto;
  }
}

.not-supported-browser {
  background: black url(../../img/background.png) no-repeat center;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 2.6vh;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
}

.not-supported-browser a {
  color: #c6225a;
  text-decoration: none;
}
