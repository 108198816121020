.imei-form {
  .device-id, .android-id {
    margin-bottom: 16px;

    .input-group {
      display: flex;
      button {
          margin-top: -5px;
      }
    }
  }

  input {
    margin-right: 6px;
    flex: 1;

    &.error {
      border: 1px solid red;
    }
  }
}
