.network-form {
  .inputs {
    margin-bottom: 15px;

    select {
      width: 100%;
      margin-top: 10px;
      padding: 2px;
    }
  }
  .profile-details {
    margin: 12px 0;

    &.hidden {
      display: none;
    }
  }

  label {
    margin-top: 20px;
    display: inline;
    float: left;
  }

  input {
    margin-top: 10px;
    width: 60%;
    float: right;
    text-align: right;
    font-size: medium;
    font-family: Helvetica, sans-serif;
  }

  .section {
    display: block;
    margin: 20px 0 10px 0;
    padding: 0;
    width: 100%;
    height: 50px;
    font-weight: bold;
    border-bottom: 1px solid white;
  }

  .fields {
    display: block;
    padding: 0;
    width: 100%;
    height: 50px;
  }

  input {
    &:invalid, &:focus:invalid {
      border-bottom: 1px solid $btn-background-color;
    }
  }

  button {
    margin-top: 20px;
  }
}
