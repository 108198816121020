
.genymotion-overlay {
  font-family: Helvetica, sans-serif;
  text-align: left;
  position: absolute;
  padding: 25px;
  width: 500px;
  left: calc(50% - 250px);
  min-height: 400px;
  top: 50px;
  background-color: $background-modal;
  color: white;
  border: none;
  border-radius: 2px;

  /* Extra Small Devices, Tablet or Phones */
  @media only screen and (max-width : 650px) {
    max-width: 500px;
    width: calc(80% - 40px) !important;
    left: 10% !important;
  }

  transition: top .4s;
  -webkit-transition: top .25s ease-out;

  &, & *, & *::after, & *::before {
    box-sizing: border-box;
  }

  &.hidden {
    top: -1000px;
  }

  .wrap:after, .horizontal:after {
    display: table;
    content: ' ';
    clear: both;
  }

  .col {
    width: 50%;
    float: left;
    padding: 0 5px;
  }

  .title {
    text-align: center;
    margin-bottom: 30px;
  }

  .horizontal {
    margin: 15px 0;
    label, input, button {
      display: inline-block;
    }
    label {
      width: 50%;
    }
  }

  button {
    color: white;
    background-color: $btn-background-color;
    padding: 11px 25px;

    border-radius: 2px;
    border: none;
    transition: all 0.3s ease;
    text-transform: uppercase;
    outline: none;
    outline-style: none;
    &:hover, &:focus{
        cursor: pointer;
        background-color: $btn-background-color-hover;
    }
    &:disabled {
      pointer-events: none;
      transition: all 0.3s ease;
      background: $btn-disabled-background;
      color: $btn-disabled-color;
      &:hover{
          color: $background-button-color;
      }
    }
  }

  label {
    display: block;
    margin-bottom: 3px;
  }
  input, textarea {
    // background-color: #444;
    background: transparent;
    color: white;

    // border: 1px solid black;
    border: none;
    border-bottom: 1px solid white;

    padding: 0 8px;
    width: 100%;
    outline: none;
  }

  input {
    height: 32px;
  }

  textarea {
    padding: 8px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: url('/img/ic-close-popup-default.svg') no-repeat top center;
    &:hover {
        cursor: pointer;
        background: url('/img/ic-close-popup-hover.svg') no-repeat top center;
    }
  }

}
