
.screencast-form {
  width: 400px;
  left: calc(50% - 200px);

  /* Extra Small Devices, Tablet or Phones */
  @media only screen and (max-width : 650px) {
    max-width: 400px;
  }

  label {
    height: 32px;
    line-height: 35px;
    margin-bottom: 0;
    margin-left: 20px;
    cursor: pointer;
  }

  .disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .screenshot, .screencast {
    display: inline-block;
    width: auto;
    margin-right: 50px;
    .action {
      width: 32px;
      min-height: 32px;
      cursor: pointer;
      display: inline-block;
      float: left;
    }
  }

  .screenshot .action {
    background: url(../img/ic-screenshot_default.svg) no-repeat center;
    background-size: 26px;
  }

  .screencast .action {
    background: url(../img/ic-screencast_default.svg) no-repeat center;
    background-size: 26px;
  }
}

.screencast-timer {
  position: relative;
  top: -10px;
  left: -90px;
  color: #c6225a;
  font-size: 20px;
  font-family: Helvetica, sans-serif;
  pointer-events: none;
  z-index: 5;
  padding: 11px 8px 8px 8px;
  border-radius: 6px;
  width: 80px;
  background-color: #303339;
  vertical-align: middle;
}

.screencast-timer::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #303339;
}

.timer-hidden {
  display: none;
}
